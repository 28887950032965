<template>
  <div>
    <el-container>
      <el-main>
        <h2>{{$t('homeTopBar.menu.profile')}}</h2>
        <div class="box" style="background: white">
          <div class="info-title">{{ $t('addLogin.myInfo.email') }}</div>
          <div class="info">{{ this.$store.getters.email }}</div>
        </div>
        <el-button class="info-title" @click="$router.push('/profile/reset_email')">{{ $t('addLogin.myInfo.edit') }}</el-button>
        <el-button class="info-title" @click="$router.push('/profile/reset_password')">{{ $t('addLogin.myInfo.reset') }}</el-button>
      </el-main>
    </el-container>
  </div>
</template>

<script>

export default {
 name: "MyInfoVue",
 data() {
  return {
  }
 },
 created() {
 },
 methods: {
 }
}
</script>

<style scoped>
.el-header {
  margin-top: 20px;
}

.el-row {
  padding: 10px;
  margin-bottom: 20px;
}

.el-col {
  padding-left: 10px;
}

.el-main {
  background-color: #BFD7ED;
  color: #333;
}

.info {
  width: 100px;
  height: 50px;
  margin-left: 50px;
}

.box {
  display: grid;
  grid-template-columns: 100px 100px 100px;
  grid-template-rows: auto;
  padding: 50px;
}

.info-title {
  width: 100px;
  margin-left: 10px;

  justify-content: flex-start;
  justify-items: start;
  align-content: flex-start;
  align-items: start;
}

.el-button {
  margin: 20px;
}
</style>
